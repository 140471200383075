var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = genRandomHexString;
function genRandomHexString(length) {
  let str = "";
  for (let i = 0; i < length; i += 1) {
    str += Math.floor(Math.random() * 16).toString(16);
  }
  return str;
}
exports = exports.default;
export default exports;
export const __esModule = exports.__esModule;